<template>
  <v-container class="py-6">
    <!-- <div v-if="loading" class="text-center mt-12">
      <v-progress-circular size="60" indeterminate color="primary" />
    </div> -->

    <v-card-title v-if="!loading">
      <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
      <v-spacer />
    </v-card-title>

    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar" />
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <dashbord-sidebar-two />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <!-- <div class="d-flex align-center mb-5">
                <img
                  v-svg-inline
                  class="icon mr-3"
                  src="@/assets/images/icons/delivery-box.svg"
                  alt=""
                />
                <h2 class="mb-0">Meus Favoritos({{ produtos.length }})</h2>
              </div> -->
              <v-row>
                <v-col v-for="(item, index) in produtos" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
                  <card-cart card-hover-shadow :content-img="item.imagem_produto" :content-text="item.designacao" :counter="item.qty" :amount="item.pvp" :produto="item" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="loading" name="dialog-loading-dados" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular size="30" class="mt-6" indeterminate color="secondary" />
          <v-col cols="12">
            <span class="font-weight-medium"> Carregando dados...</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import CardCart from '@/components/cartCard/CardCart'
  import { LOGIN_URL, SERVIDOR } from '@/configs/api'
  import axios from 'axios'
  import DashbordSidebarTwo from '@/components/DashboardSidebarTwo.vue'

  export default {
    components: {
      CardCart,
      DashbordSidebarTwo,
    },
    data () {
      return {
        SERVER: SERVIDOR,
        itemsLink: [
          {
            text: 'Início',
            disabled: false,
            href: '/portal/pages/cliente/dashboard',
          },
          {
            text: 'Produtos Favoritos',
            disabled: true,
            href: '/',
          },
        ],

        isSidebar: false,
        loading: false,
        produtos: [],
        tab: null,
        page: 1,
        itemsPerPage: 8,
      }
    },
    computed: {},
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    mounted () {
      this.getAllProdutosFavoritos()
      this.checkUserIsAuth()
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      checkUserIsAuth () {
        if (localStorage.getItem('TOKEN') == null) {
          this.$router.push('/')
        }
      },
      getAllProdutosFavoritos () {
        this.loading = true
        var TOKEN_SESSAO = localStorage.getItem('TOKEN')
        const url = `${LOGIN_URL}/empresa/listarProdutosFavoritos`
        axios
          .get(url, {
            headers: {
              Authorization: 'Bearer ' + TOKEN_SESSAO,
            },
          })
          .then((response) => {
            this.produtos = response.data.produtos
            // alert(JSON.stringify(response.data.produtos))
            setTimeout(() => {
              this.loading = false
            }, 50)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;
    img {
      border: 4px solid #fff;
    }
  }
  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
